import Swiper from 'swiper';
import {Autoplay, Pagination, Navigation, } from 'swiper/modules';
Swiper.use([Autoplay, Pagination, Navigation]);

const testimonialSwiper = new Swiper('.testimonialSwiper', {
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    dynamicBullets: true,
  },
  autoplay: {
    delay: 5000,
  },
  breakpoints: {
    1200: {
      slidesPerView: 1.8,
      spaceBetween: 150,
      centeredSlides: true,
    },
    700: {
      slidesPerView: 1.5,
      spaceBetween: 80,
      centeredSlides: true,
    },
    420: {
      slidesPerView: 1.5,
      centeredSlides: true,
      spaceBetween: 40,
    },
    250: {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 40,
    },
  },

});

const cardsSwiper = new Swiper('.cardsSwiper', {
  slidesPerView: 5,
  centerInsufficientSlides: true,
  navigation: {
    nextEl: '.swiper-button-cards-next',
    prevEl: '.swiper-button-cards-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    dynamicBullets: true,
  },
  breakpoints: {
    1200: {
      slidesPerView: 5,
      spaceBetween: 10,
    },
    800: {
      slidesPerView: 3.5,
      spaceBetween: 10,
    },
    400: {
      slidesPerView: 2.5,
      spaceBetween: 10,
    },
    300: {
      slidesPerView: 2,
      centeredSlides: false,
      spaceBetween: 10,
    },
  },
});

const recentNewsSwiper = new Swiper('.recent-posts__slider',{
  sliderPerView: 1,
  loop: true,
  spaceBetween: 30,
  // autoplay: {
  //   delay: 5000
  // },
  pagination: {
    el: '.recent-news-pagination',
    clickable: true,
    dynamicBullets: true,
  },
  breakpoints: {
    650:{
      slidesPerView: 2,
    },
  },
});
